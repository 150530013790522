<template>
  <div>
    <div v-if="loading" class="text-center p-4">
      <b-spinner />
    </div>
    <print-component
      v-if="printNow"
      :printNow="printNow"
      :show="show"
      @done="donePrint"
      :titulo="`Guias Médicas ${paraMedico ? ' Médico' : ''} ${tipo_relatorio==='Solicitadas'?'Solicitadas':''}`"
      :filtros="
        `Data: ${textoPeriodo} | Clínica: ${
          empresas && empresas.length > 0 ? empresas[0].sigla : 'Todas'
        } | Médico: ${
          medicos.length <= 0
            ? 'Todos'
            : medicos[0].razao_social
            ? medicos[0].razao_social
            : medicos[0].nome
        }`
      "
      style="max-width:500px"
    >
      <div class="pdf-content">
        <table
          class="table table-striped table-sm text-left"
          style="font-size: 10px"
        >
          <thead>
            <tr>
              <th>Venda</th>
              <th v-if="!paraMedico">Data</th>
              <th>Paciente</th>
              <th>Item</th>
              <th v-if="!paraMedico">
                {{
                  tipo_relatorio == "Solicitadas"
                    ? "Solicitante / Laboratório"
                    : "Médico"
                }}
              </th>
              <th class="text-center" v-if="!paraMedico">Valor</th>
              <th>{{ !paraMedico ? "Custo" : "Valor" }}</th>
            </tr>
          </thead>
          <tbody v-if="lancamentos && lancamentos.length > 0" class="pdf-item">
            <tr
              v-for="venda of lancamentos"
              :key="'lin_relat_guia' + venda.venda_id + Math.random(9999)"
              @click="openDetail(venda)"
            >
              <td>
                {{ venda.venda_id }}
                <br />
                <small>{{ venda.clinica }}</small>
              </td>
              <td v-if="!paraMedico">
                {{ venda.data | moment("DD/MM/YYYY") }}
              </td>
              <td>{{ venda.paciente }}</td>
              <td>
                <!-- <div
                  class="text-nowrap text-left"
                  v-if="venda.tipo_venda === 'Exame'"
                >
                  <div v-for="(exame, key) of venda.item" :key="key">
                    {{ exame }}
                  </div>
                </div>

                <div v-else> -->
                <div
                  v-if="
                    !venda.exames ||
                      !venda.exames.exames ||
                      venda.exames.exames.length <= 0
                  "
                >
                  {{ venda.exame }} <br />
                  <small>{{ venda.tipo_consulta }}</small>
                </div>
                <div
                  v-if="
                    venda.exames &&
                      venda.exames.exames &&
                      venda.exames.exames.length > 0
                  "
                >
                  <div
                    class="pl"
                    v-for="exx in venda.exames.exames"
                    :key="`exame_${exx.id}_${Math.random(23232)}`"
                  >
                    {{ exx.nome }} <br />
                  </div>
                </div>
                <!-- </div> -->
              </td>
              <td v-if="!paraMedico">
                <span v-if="tipo_relatorio == 'Solicitadas'">
                  <i class=" fa fa-user-md"></i> {{ venda.medicoSolicitante }}
                  <br />
                  <i class=" fas fa-hospital"></i>
                </span>
                {{ venda.medico }}
              </td>
              <td class="text-center" v-if="!paraMedico">
                {{ venda.vl_total | currency }}
              </td>
              <td class="text-center">{{ venda.vl_custo | currency }}</td>
            </tr>
          </tbody>
        </table>

        <div
          class="row pdf-item"
          :class="{ 'html2pdf__page-break': lancamentos.length > 25 }"
        >
          <div class="col-6">
            <b>Resumo por Tipo</b>
            <table class="table table-striped table-sm" style="font-size: 11px">
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th class="text-center">Qtd.</th>
                  <th class="text-right" v-if="!paraMedico">Venda</th>
                  <th class="text-right" v-if="!paraMedico">Custo</th>
                  <th class="text-right" v-if="paraMedico">Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="pgt in totalizador.resumoPorTipo"
                  :key="`totalizador_pgt${pgt.tipo}`"
                >
                  <td>{{ pgt.tipo }}</td>
                  <td class="text-center">{{ pgt.qtd }}</td>
                  <td class="text-right" v-if="!paraMedico">
                    {{ pgt.vl_total | currencyWithMask }}
                  </td>
                  <td class="text-right" v-if="!paraMedico">
                    {{ pgt.vl_custo | currencyWithMask }}
                  </td>
                  <td class="text-right" v-if="paraMedico">
                    {{ pgt.vl_custo | currencyWithMask }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-6 text-center">
            <b>
              <h4>
                Total Geral
                <hr class="mb-1" />
              </h4>
              <div class="text-right">
                <small>Quantidade: {{ lancamentos.length }}</small> <br />
                <span v-if="!paraMedico">
                  Valor:
                  {{
                    lancamentos.reduce((ret, vl) => {
                      ret += vl.vl_total;
                      return ret;
                    }, 0) | currencyWithMask
                  }}
                  <br />
                </span>
                {{ !paraMedico ? "Custo" : "Valor" }}:
                {{
                  lancamentos.reduce((ret, vl) => {
                    ret += vl.vl_custo;
                    return ret;
                  }, 0) | currencyWithMask
                }}<br />
              </div>
            </b>
          </div>
        </div>
      </div>
    </print-component>

    <b-modal
      id="modal-venda-detail"
      size="lg"
      title="Detalhes de venda"
      hide-footer
      no-enforce-focus
      @hide="closeVendaDetail"
    >
      <venda-detail
        :vendaId="vendaEdit"
        @close="closeVendaDetail"
        ref="vendaDetails"
        @cancelarVenda="cancelarVenda($event)"
      />
    </b-modal>
  </div>
</template>

<script>
// import { BIconTelephoneMinus } from "bootstrap-vue";
import CaixasLib from "../../../libs/CaixasLib";
import PrintComponent from "../../Print/PrintComponent.vue";

import moment from "moment";
import VendaDetail from "../../Caixas/Vendas/VendaDetail.vue";
export default {
  components: {
    PrintComponent,
    VendaDetail
  },
  props: {
    opts: Object,
    periodo: Object,
    empresas: Array,
    medicos: Array,
    paraMedico: {
      type: Boolean,
      default: false
    },
    show: { type: Boolean, default: false },
    especialidades: Array,
    tipo_venda: Array,
    tipo_relatorio: {
      type: String,
      default: ""
    }
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      lancamentos: [],
      totalizador: {
        pagamentos: [],
        resumoPorTipo: [],
        qtd: 0,
        vl_total: 0,
        vl_custo: 0
      },
      printNow: false,
      medicosSel: [],
      textoPeriodo: "",
      loading: false,
      vendaEdit: null
    };
  },
  watch: {
    $props: {
      deep: true,
      handler() {
        // this.carregar();
      }
    }
  },
  computed: {},
  methods: {
    async closeVendaDetail() {
      this.vendaEdit = null;
      this.$bvModal.hide("modal-venda-detail");
      await this.carregarListagem();
    },
    openDetail(venda) {
      if (venda) {
        this.vendaEdit = venda.venda_id;
        this.$bvModal.show("modal-venda-detail");
      }
    },
    donePrint() {
      this.$emit("donePrint");
    },
    async carregar() {
      // console.log(
      //   "----------------->",
      //   this.periodo,
      //   this.empresas,
      //   this.medicos
      // );
      if (!this.periodo || !this.empresas) {
        console.log("DADOS INCOMPLETOS PARA AS GUIAS");
        return;
      }
      this.loading = true;
      if (this.periodo) {
        this.periodo.dt_i = moment(this.periodo.dt_i).format("YYYY-MM-DD");
        this.periodo.dt_f = moment(
          !this.periodo.dt_f ? this.periodo.dt_i : this.periodo.dt_f
        ).format("YYYY-MM-DD");
        this.textoPeriodo =
          moment(this.periodo.dt_i).format("DD/MM/YYYY") +
          " até " +
          moment(this.periodo.dt_f).format("DD/MM/YYYY");
      }
      if (this.medicos) {
        this.medicosSel = this.medicos.map(x => x.id);
      }
      let res = await CaixasLib.relatorioLancamentos({
        periodo: this.periodo,
        empresas: this.empresas.map(s => s.id),
        medicos: this.medicosSel,
        especialidades: this.especialidades,
        tipo_venda: this.tipo_venda,
        tipo_relatorio: this.tipo_relatorio
      });

      console.log(333, res.data, this.medicosSel);

      if (res) {
        if(this.tipo_relatorio === 'Solicitadas'){
           res.data = res.data.filter(x =>
              x.items.some(i =>i.medicoSolicitanteID && i.medicoSolicitanteID>0)
            );
        }
        if (this.medicosSel && this.medicosSel.length > 0) {
          if (
            !this.tipo_relatorio ||
            this.tipo_relatorio == "" ||
            this.tipos_relatorio == "Emitidas"
          ) {
            res.data = res.data.filter(x =>
              x.items.some(i => this.medicosSel.includes(i.fornecedor_id))
            );
          } else {
            res.data = res.data.filter(x =>
              x.items.some(i => this.medicosSel.includes(i.medicoSolicitanteID))
            );
          }
        }
        this.lancamentos = res.data.reduce((ret, vl) => {
          for (let it of vl.items.filter(x =>
            this.medicosSel && this.medicosSel.length > 0
              ? !this.tipo_relatorio ||
                this.tipo_relatorio == "" ||
                this.tipos_relatorio == "Emitidas"
                ? this.medicosSel.includes(x.fornecedor_id)
                : this.medicosSel.includes(x.medicoSolicitanteID)
              : true &&
                (this.paraMedico
                  ? ["Exame", "Consulta", "Acerto"].includes(x.tipo_venda)
                  : true)
          )) {
            ret.push({
              venda_id: vl.id,
              exame: it.item,
              clinica: vl.sigla,
              data: vl.data,
              paciente: it.paciente,
              tipo_consulta: it.tipo_venda,
              item_id: it.vid,
              vl_total: it.vl_total,
              vl_custo: it.vl_custo,
              medicoSolicitante: it.medicoSolicitante,
              medico: it.medico,
              exames: it.exames
                ? typeof it.exames === "string"
                  ? JSON.parse(it.exames)
                  : it.exames
                : null
            });
          }
          return ret;
        }, []);

        this.lancamentos = this.lancamentos.sort((a, b) => {
          return a.paciente &&
            b.paciente &&
            a.paciente.trim() > b.paciente.trim()
            ? 1
            : -1;
        });
        console.log("-- iniciando totalizador --", this.lancamentos);

        this.totalizador = this.lancamentos.reduce(
          (ret, vl) => {
            let has = ret.resumoPorTipo.findIndex(
              x =>
                (vl.tipo_consulta && x.tipo == vl.tipo_consulta) ||
                (!vl.tipo_consulta &&
                  vl.exames &&
                  vl.exames.exames &&
                  vl.exames.exames.length > 0 &&
                  x.tipo == vl.exames.exames[0].tipo)
            );
            // console.log("achou", has, vl);
            if (has < 0 || has == null) {
              ret.resumoPorTipo.push({
                tipo: vl.tipo_consulta
                  ? vl.tipo_consulta
                  : vl.exames && vl.exames.exames && vl.exames.exames.length > 0
                  ? vl.exames.exames[0].tipo
                  : "Outros",
                qtd: 1,
                vl_custo: vl.vl_custo,
                vl_total: vl.vl_total
              });
            } else {
              ret.resumoPorTipo[has].qtd++;
              ret.resumoPorTipo[has].vl_custo += vl.vl_custo;
              ret.resumoPorTipo[has].vl_total += vl.vl_total;
            }
            return ret;
          },
          {
            pagamentos: [],
            qtd: 0,
            vl_total: 0,
            vl_custo: 0,
            resumoPorTipo: []
          }
        );
      } else {
        alert("Nada encontrado!");
      }
      this.printNow = true;
      this.loading = false;

      this.$emit("ok", true);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
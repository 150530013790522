var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card mt-4",staticStyle:{"max-width":"800px","margin":"0 auto !important"}},[_c('div',{staticClass:"card-body"},[_c('Filtros',{attrs:{"title":`Guias Médicas`,"initial":_vm.filtros},on:{"filtrar":_vm.filtrar}},[_c('div',{staticClass:"row pb-2"},[_c('div',{staticClass:"col-12 col-sm-4 text-left pr-0"},[_c('label',{staticStyle:{"margin-bottom":"7px"}},[_vm._v("Tipo de Lançamento / Venda")]),_c('v-select',{attrs:{"multiple":"","options":[
                'Consulta',
                'Exame',
                'Fidelização',
                'Produto',
                'Acerto'
              ]},model:{value:(_vm.filtros.tipo_venda),callback:function ($$v) {_vm.$set(_vm.filtros, "tipo_venda", $$v)},expression:"filtros.tipo_venda"}})],1),_c('div',{staticClass:"col-12 col-sm-4 text-left pl-2 pr-0"},[_c('b-form-group',{attrs:{"label":"Tipo relatório"}},[_c('b-select',{staticStyle:{"padding":"5px","font-size":"11px"},attrs:{"options":['Emitidas', 'Solicitadas']},model:{value:(_vm.filtros.tipo_relatorio),callback:function ($$v) {_vm.$set(_vm.filtros, "tipo_relatorio", $$v)},expression:"filtros.tipo_relatorio"}})],1)],1),_c('div',{staticClass:"col-12 col-sm-4 text-center pt-4"},[_c('h2',[_c('b-check',{attrs:{"switch":"","size":"lg","value":true,"unchecked-value":false},model:{value:(_vm.filtros.paraMedico),callback:function ($$v) {_vm.$set(_vm.filtros, "paraMedico", $$v)},expression:"filtros.paraMedico"}},[_vm._v("Para médico")])],1)])])])],1)]),(
      _vm.exibir &&
        _vm.filtros &&
        (!_vm.filtros.periodo || !_vm.filtros.periodo.dt_i || !_vm.filtros.periodo.dt_f)
    )?_c('div',{staticClass:"alert alert-danger text-center"},[_c('h1',[_c('b-icon-exclamation-diamond')],1),_c('br'),_vm._v(" Seleciona ao menis periodo para iniciar a busca. ")]):_c('div',{staticStyle:{"margin":"0 auto","width":"100%","max-width":"800px","margin-top":"20px"}},[_c('hr'),_c('div',{staticClass:"text-right"},[_c('b-btn',{attrs:{"variant":"none"},on:{"click":_vm.imp}},[_c('b-icon-printer'),_vm._v(" Imprimir")],1)],1),_c('div',{attrs:{"id":"printDIV"}},[(_vm.exibir)?_c('Guias',{attrs:{"paraMedico":_vm.filtros.paraMedico,"periodo":_vm.filtros.periodo,"medicos":_vm.filtros.medicos,"empresas":_vm.filtros.empresas,"show":_vm.show,"especialidades":_vm.filtros.especialidades,"tipo_venda":_vm.filtros.tipo_venda,"tipo_relatorio":_vm.filtros.tipo_relatorio}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
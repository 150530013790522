<template>
  <div>
    <div class="card mt-4" style="max-width: 800px; margin: 0 auto !important">
      <div class="card-body">
        <Filtros :title="`Guias Médicas`" @filtrar="filtrar" :initial="filtros">
          <div class="row pb-2">
            <div class="col-12 col-sm-4 text-left pr-0">
              <label style="margin-bottom:7px"
                >Tipo de Lançamento / Venda</label
              >
              <v-select
                multiple
                v-model="filtros.tipo_venda"
                :options="[
                  'Consulta',
                  'Exame',
                  'Fidelização',
                  'Produto',
                  'Acerto'
                ]"
              />
            </div>
            <div class="col-12 col-sm-4 text-left pl-2 pr-0">
              <b-form-group label="Tipo relatório">
                <b-select
                  :options="['Emitidas', 'Solicitadas']"
                  v-model="filtros.tipo_relatorio"
                  style="padding:5px; font-size:11px"
                />
              </b-form-group>
            </div>
            <div class="col-12 col-sm-4 text-center pt-4">
              <h2>
                <b-check
                  switch
                  size="lg"
                  v-model="filtros.paraMedico"
                  :value="true"
                  :unchecked-value="false"
                  >Para médico</b-check
                >
              </h2>
            </div>
          </div>
        </Filtros>
      </div>
    </div>

    <div
      class="alert alert-danger text-center"
      v-if="
        exibir &&
          filtros &&
          (!filtros.periodo || !filtros.periodo.dt_i || !filtros.periodo.dt_f)
      "
    >
      <h1>
        <b-icon-exclamation-diamond />
      </h1>
      <br />
      Seleciona ao menis periodo para iniciar a busca.
    </div>

    <div
      style="margin: 0 auto; width: 100%; max-width: 800px; margin-top: 20px"
      v-else
    >
      <hr />
      <div class="text-right">
        <b-btn variant="none" @click="imp"><b-icon-printer /> Imprimir</b-btn>
      </div>
      <div id="printDIV" style="">
        <Guias
          v-if="exibir"
          :paraMedico="filtros.paraMedico"
          :periodo="filtros.periodo"
          :medicos="filtros.medicos"
          :empresas="filtros.empresas"
          :show="show"
          :especialidades="filtros.especialidades"
          :tipo_venda="filtros.tipo_venda"
          :tipo_relatorio="filtros.tipo_relatorio"
        >
        </Guias>
      </div>
    </div>
  </div>
</template>

<script>
import Filtros from "../../components/Relatorios/Filtros.vue";
import Guias from "../../components/Relatorios/Guias/Guias.vue";
import PrinterWindowHelper from "../../helpers/PrinterWindowHelper";

export default {
  components: {
    Filtros,
    Guias
  },
  props: {},
  mounted() {},
  data() {
    return {
      totalizador: {
        pagamentos: [],
        resumoPorTipo: [],
        qtd: 0,
        vl_total: 0,
        vl_custo: 0
      },
      exibir: false,
      filtros: {},
      print: false
    };
  },
  watch: {
    filtros: {
      deep: true,
      handler() {
        this.exibir = false;
        this.print = false;
      }
    }
  },
  computed: {},
  methods: {
    imp() {
      //this.show = false;
      //this.exibir = false;
      PrinterWindowHelper(document.getElementById("printDIV").innerHTML);
      this.$nextTick();
      setTimeout(() => {
        this.exibir = true;
      }, 300);
    },
    async filtrar(filtros) {
      this.show = true;
      this.exibir = false;
      this.filtros = filtros;
      this.$nextTick();
      setTimeout(() => {
        this.exibir = true;
      }, 300);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>